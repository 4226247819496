<template>
  <section>
    <el-dialog v-model="dialogShow"  
      :visible.sync="dialogShow"
               @closed="checkclose"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               style="width: 101%; left:0%; top:-10px;height:700px;"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;position:relative;top:-3px;">{{myheadText}}</span>
      </template>

      <el-button-group>
        <el-button type="primary" @click="handleAdd" size="mini" icon="el-icon-plus" >新增</el-button>
        <el-button type="primary" @click="handleDelete" size="mini" icon="el-icon-minus" >移除</el-button>
      </el-button-group>
      <span style="margin-left:5px;color:#999;font-size:9px"><i class="el-icon-warning-outline"></i>关注成员后，其下所有进行中的任务产生进度时将第一时间为您推送消息</span>
      <el-table
        :data="datas"
        highlight-current-row
        @current-change="selectCurrentRow"
        :row-style="{height:'40px'}"
        :cell-style="{padding:'0px'}"
        :header-cell-style="{background:'#dce4f1',color:'#000000'}"
        :row-class-name="tableRowClassName"
        max-height="350"
        style="width: 100%;margin-top:5px">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="团队成员" prop="PersonUsername" align="center">
          <template slot-scope="scope">
            <span style="cursor:default">{{scope.row.PersonUsername}}</span>
            <el-button type="text" size="medium" @click="lookWaitDo(scope.row)" v-if="scope.row.Enabled == 1">待办任务</el-button>
          </template>
        </el-table-column>
        <el-table-column label="维护时间" prop="LastUpdateTime" align="center"></el-table-column>
        <el-table-column label="状态" prop="Enabled" align="center" min-width="100px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.Enabled == 0" effect="dark" type="info">暂不关注</el-tag>
            <el-tag v-if="scope.row.Enabled == 1" type="" >关注中</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" prop="Operation" min-width="65px">
          <template slot-scope="scope">
              <el-button type="text" size="mini" @click="changeCollectState(scope.row,0)" v-if="scope.row.Enabled == 1">暂不关注</el-button>
              <el-button type="text" size="mini" @click="changeCollectState(scope.row,1)" v-else>继续关注</el-button>
          </template>
        </el-table-column>
      </el-table>
      <br/>
      <!--新增界面-->
      <el-dialog
        :visible.sync="addFormVisible"
        v-model="addFormVisible"
        @close="diaclose"
        :modal="false"
        :center="true"
        style="width:80%;left:10%;top:70px;">
      <template slot="title">
          <span style="color:#FFFFFF;font-size: 16px;">新增团队成员</span>
      </template>
        <el-form
          :model="addForm"
          label-width="85px"
          :rules="addFormRules"
          ref="addForm"
          style="white-space: nowrap">
        
          <el-row>
            <el-col :span="4">&nbsp;</el-col>
            <el-col :span="14">
              <el-row>
                <el-col :span="20">
                  <el-form-item label="团队成员:" prop="PersonName">
                    <el-input v-model="addForm.PersonName" placeholder="请选择团队成员" readonly>{{ccusername}}</el-input>
                    <el-button type="text" size="mini" @click="handleChooseCCUser">选择人员</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="goon" @click.native="addSubmit">提交</el-button>
        </div>
      </el-dialog>
    </el-dialog>

        <el-dialog
        title="选择人员"
        :visible.sync="addUserVisible"
        v-model="addUserVisible"
        width="75%"
        center
        append-to-body
        >
        <UserChoose
            :deptId="chooseDeptId"
            :data="choosedusers"
            :all="true"
            :single="false"
            @callback="chooseUserCallBack"
        ></UserChoose>
        </el-dialog>

        <el-drawer
          :title="drawerTitle"
          :visible.sync="drawerVisiable"
          direction="rtl"
          :show-close="false"
          size="50%">
          <el-table 
            :data="waitDoTaskData"
            highlight-current-row
            row-key="Id"
            lazy
            :indent="30"
            :load="load"
            :tree-props="{ children: 'children', hasChildren: 'IsHasChildren' }"
            :fit="true"
            :cell-style="mycellStyle"
            @row-dblclick="checkInfo">
              <el-table-column property="WorkItem" label="工作项目"  min-width="350px">
                <template slot-scope="scope">
                  <span :style="{ cursor: 'default', display: 'block', 'padding-left': '20px', 'margin-top': scope.row.IsHasChildren ? '-22px' : '0px', 'margin-bottom': scope.row.IsHasChildren ? '0px' : '15px', 'margin-left': scope.row.MarginLeft + 'px' }">{{ scope.row.Number }}、{{scope.row.WorkItem}}</span>
                </template>
              </el-table-column>
              <el-table-column property="PlanComplateTime" label="计划完成时间" :formatter="formatPlanComplateTime" min-width="130px">
                <template slot-scope="scope">
                  <div v-if="new Date() > new Date(new Date(new Date(scope.row.PlanComplateTime).toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1) && scope.row.TaskProgressValue < 100">
                    <font color="red">{{ formatPlanComplateTime(scope.row) }}</font>
                    &nbsp;<el-tag v-if="scope.row.IsProlongTime" type="success" size="mini">延</el-tag>
                  </div>
                  <div v-else>
                    <font color="black">{{ formatPlanComplateTime(scope.row) }}</font>
                    &nbsp;<el-tag v-if="scope.row.IsProlongTime" type="success" size="mini">延</el-tag>
                  </div>
              </template>
              </el-table-column>
              <el-table-column label="责任人" prop="PersonOfDuty" min-width="63px"></el-table-column>
              <el-table-column label="任务状态" prop="Status" align="center" min-width="100px">
                <template slot-scope="scope">
                  <div>
                    <div v-if="scope.row.TaskProgressValue < 100" style="font-size: 10px">进度:{{ formatProgress(scope.row) }}</div>
                    <el-tag v-if="scope.row.Status == 0" effect="dark"> 进行中</el-tag>
                    <el-tag v-if="scope.row.Status == 2" effect="dark" type="success">已完成</el-tag>
                    <el-tag v-if="scope.row.Status == -1" effect="dark" type="info">已取消</el-tag>
                    <el-tag v-if="scope.row.Status == 3" effect="dark" type="danger">退回</el-tag>
                    <el-tag v-if="scope.row.Status == 1" effect="dark" type="warning">已完成审核中</el-tag>
                    <el-tag v-if="scope.row.Status == 4" effect="dark" type="warning">新建任务待审核</el-tag>
                    <el-tag v-if="scope.row.Status == 5" effect="dark" type="danger">取消任务待审核</el-tag>
                    <el-tag v-if="scope.row.Status == 6" effect="dark" type="danger">新建任务退回</el-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="任务类型" prop="CommonTaskClassifyText" min-width="100px" :formatter="formatCommonTaskClassifyText"></el-table-column>
              <el-table-column label="创建时间" prop="CreateTime" min-width="80px" :formatter="formatCreateTime">
                <template slot-scope="scope">
                  <el-tooltip placement="left" effect="light">
                    <div slot="content">
                      {{ formatTipCreateTime(scope.row) }}
                    </div>
                    <div>
                      {{ formatCreateTime(scope.row) }}
                    </div>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="操作" min-width="80px">
                <template slot-scope="scope">
                  <p><el-link type="primary" :underline="false" @click="checkInfo(scope.row)" v-if="scope.row.Status != 5">查看</el-link></p>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination align="center" @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next, jumper" :page-count="total" :total="totaldata"> </el-pagination>
        </el-drawer>

        <taskinfo v-if="checkVisible" :data="checkForm" @closeFunction="taskinfoCheckclose" type="normal"></taskinfo>

  </section>
</template>
<script>
import { Loading } from 'element-ui'
import util from "../../util/date";
import {
  GetUserPostList,
  AddTaskCollectPerson,
  EnableTaskCollectPerson,
  QueryCollectPersonListByCreatedId,
  RemoveTaskCollectPerson,
  QueryPageWaitDoTasksByUserCode,
  QueryChildTasksByParentId,
  QuerySearchQuanxianListByUsercode
} from "../api/oa";
import Userchoosetool from "../components/Userchoosetool";
import UserChoose from "../components/UserChoose";
import taskinfo from '../components/taskinfo'
import UploadFiles from "../components/UploadFiles";
export default {
  name: 'collectperson',
  components: { UserChoose, Userchoosetool, taskinfo, UploadFiles },
  data () {
    return {
      currentPersonUserId:'',
      waitDoTaskData:[],
      mapLevel: new Map(),
      maps: new Map(),
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 8, // 每页的数据条数
      page: 1,
      drawerTitle:'',
      drawerVisiable:false,
      popo1:false,
      filters: {
        name: ""
      },
      CommonTaskClassifyText:'',
      loading: '',
      currentYearplanActiveId: null,
      currentActiveId: null,
      // editor: null,
      myheadText:'查看关注的团队成员',
      mytype:'normal',
      checkVisible: false,
      checkForm: {
        Id: null,
        WorkItemType: null,
        CommonTaskClassify: null,
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
        IsNeedApproval: null,
        Files: []
      },
      addProgressForm: {            //进度新增表单初始化
        ProgressDescription: null,
        CreatedBy: null,
        CreatedId: null
      },
      addProgressFormRule: {
        ProgressDescription: [
          { required: true, message: '请输入进度描述', trigger: 'blur' }
        ]
      },
      WorkItemType: null,
      activeName: 'tabTask',
      pValue1: 0,   //进度条数值
      pValue2: 0,
      taskprogress: [],
      istaskpro: true,
      dialogShow:false,
      addProgressVisible: false,
      progresscheck:false,
      operation1: true,
      fileList: [],
      key: 0,
      yearplans:[],
      mapReview:new Map(),
      datas: [],
      addFormVisible: false,
      addForm: {
        PersonUserId: null,
        PersonName: null
      },
      addFormRules: {
        PersonName: [
          { required: true, message: "请选择团队成员", trigger: "blur" }
        ]
      },
      doublepeople: [],
      assignpeo: "assignpeo",
      ccusername:'',
      ccusername2:'',
      fabuusername:'',
      editFormVisible: false,
      editForm: {
        Id: null,
        ApprovalName: null,
        ApprovalCode: null
      },
      editCCForm: {
        Id: null,
        CCUserName: null,
        CCUserCode: null
      },
      editFabuForm: {
        Id: null,
        UsePeopleName: null,
        UsePeopleCode: null
      },
      editCCFormVisible: false,
      editFabuFormVisible: false,
      addUserVisible:false,
      addUserVisible2:false,
      addUserVisible3:false,
      choosedusers: [],//抄送人
      choosedfabuusers: [],//发布人
      chooseDeptId:''
    }
  },
  props: ['headerText','data','type'],// 接收父组件的方法
  watch: {
    content () {
      // this.editor.txt.html(this.content)
    }
  },
  methods:
  {
    mycellStyle(row, column, rowIndex, columnIndex) {
      if (row.row.Level === 1) {
        return 'background:#fff'
      } else if (row.row.Level === 2) {
        return 'background:#e9f3ff'
      } else if (row.row.Level === 3) {
        return 'background:#d4e9ff'
      } else if (row.row.Level === 4) {
        return 'background:#bfdeff'
      } else if (row.row.Level === 5) {
        return 'background:#aad2ff'
      } else if (row.row.Level === 6) {
        return 'background:#96c8ff'
      } else if (row.row.Level === 7) {
        return 'background:#81bdff'
      } else if (row.row.Level === 8) {
        return 'background:#6eb3ff'
      } else if (row.row.Level === 9) {
        return 'background:#5ba8ff'
      } else if (row.row.Level === 10) {
        return 'background:#499eff'
      }
      return ''
    },
    rowformat(element) {
      var has = this.mapLevel.has(element.Tag)
      var parentLevel = 0
      if (has) {
        parentLevel = this.mapLevel.get(element.Tag)
      } else {
        parentLevel = this.mapLevel.get(element.ParentId)
      }
      var level = parentLevel + 1
      if (level == 2) {
        element.MarginLeft = 30
      } else if (level == 3) {
        element.MarginLeft = 60
      } else if (level == 4) {
        element.MarginLeft = 90
      } else if (level == 5) {
        element.MarginLeft = 120
      } else if (level == 6) {
        element.MarginLeft = 150
      } else if (level == 7) {
        element.MarginLeft = 180
      } else if (level == 8) {
        element.MarginLeft = 210
      } else if (level == 9) {
        element.MarginLeft = 240
      } else if (level == 10) {
        element.MarginLeft = 270
      }
      element.Level = level
      this.mapLevel.set(element.Id, level)
      return element
    },
    load(tree, treeNode, resolve) {
      // 将当前选中节点数据存储到map中
      this.maps.set(tree.Id, { tree, treeNode, resolve })
      let para = {
        taskId: tree.Id
      }
      QueryChildTasksByParentId(para).then((res) => {
        var myarr = new Array()
        var number = 0
        res.data.response.forEach((element) => {
          element.Number = ++number
          element = this.rowformat(element)
          myarr.push(element)
        })
        resolve(myarr)
        // resolve(res.data.response)
      })
    },
    //双击查看详细信息
    checkInfo(row) {
      this.checkVisible = true
      this.checkForm = row
    },
    handleCurrentChange(val) {
      this.page = val
      this.loadWaitDoTaskData()
    },
    loadWaitDoTaskData(){
      let para = {
        userCode: this.currentPersonUserId,
        pageIndex: this.page,
        pageSize: this.pageSize,
        queryKey: this.filters.name
      }
      QueryPageWaitDoTasksByUserCode(para).then((res) => {
        this.total = res.data.response.pageCount
        this.totaldata = res.data.response.dataCount
        var arr = new Array()
        var number = (this.page - 1) * this.pageSize
        res.data.response.data.forEach((element) => {
          // console.log(element);
          element.Number = ++number
          element.MarginLeft = 3
          element.Level = 1
          this.mapLevel.set(element.Id, 1)
          arr.push(element)
        })
        this.waitDoTaskData = arr
      })
    },
    formatPlanComplateTime: function (row, colume) {
      return !row.PlanComplateTime || row.PlanComplateTime == '' ? '' : util.formatDate.format(new Date(row.PlanComplateTime), 'yyyy-MM-dd')
    },
    formatCreateTime: function (row, column) {
      return !row.CreateTime || row.CreateTime == '' ? '' : util.formatDate.dateformat(new Date(row.CreateTime))
    },
    formatTipCreateTime: function (row, column) {
      return !row.CreateTime || row.CreateTime == '' ? '' : util.formatDate.format(new Date(row.CreateTime), 'yyyy-MM-dd hh:mm')
    },
    formatCommonTaskClassifyText: function (row, colume) {
      if (row.TaskClassify == 1) {
        return row.CommonTaskClassifyText
      } else {
        if (row.TaskClassify == 2) {
          return '跨部门协调'
        } else if (row.TaskClassify == 3) {
          return '例会项目'
        } else if (row.TaskClassify == 4) {
          return '年度计划'
        } else if (row.TaskClassify == 5) {
          return '专项会议'
        } else {
          return '其他'
        }
      }
    },
    formatProgress: function (row, col) {
      return row.TaskProgressValue + '%'
    },
    lookWaitDo(row){
      let self = this;
      self.drawerTitle = '团队成员'+row.PersonUsername+'的待办任务(双击查看任务详情、点击左侧阴影收起任务列表)';
      self.drawerVisiable = true;
      self.currentPersonUserId = row.PersonUserId;
      self.loadWaitDoTaskData();
    },
    changeCollectState(row,val){
      let self = this;
      var para = {
        Id:row.Id,
        Enabled:val
      }
      this.$confirm("确认要改变关注状态吗？", "提示", {}).then(() => {
        this.Loadingstart();
        EnableTaskCollectPerson(para).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success"
            });
            this.Loadignend();
            this.getRegularMeetings();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error"
            });
          }
        });
      });
    },
    //新增提交
    addSubmit() {
      let self = this;
      if (
        this.addForm.PersonName == null ||
        this.addForm.PersonUserId == null
      ) {
        this.$message({
          message: '请选择团队成员',
          type: "warning"
        });
        return;
      }
      var names = this.addForm.PersonName.split(',');
      var ids = this.addForm.PersonUserId.split(',');
      var nameArr = [];
      var idArr = [];
      for(var a = 0;a<names.length;a++){
        var elementName = names[a];
        var elementPersonId = ids[a];
        var result = self.datas.filter(n=>n.PersonUsername==elementName);
        if(result.length==0){
          nameArr.push(elementName);
          idArr.push(elementPersonId);
        }
      }
      // for(var a=0;a<this.datas.length;a++)
      // {
      //   if(this.datas[a].MeetingName==this.addForm.MeetingName)
      //   {
      //     exists=true;
      //     break;
      //   }
      // }
      // if(exists)
      // {
      //   this.$message({
      //     message: "会议名称不能重复，请检查！",
      //     type: "warning"
      //   });
      //   return;
      // }
      console.log(nameArr)
      console.log(idArr)
      var user = JSON.parse(window.localStorage.user);
      var para = [];
      for(var a=0;a<idArr.length;a++){
        para.push({
          PersonUserId: idArr[a],
          PersonUsername: nameArr[a],
          CreatedId: user.sub,
          CreatedBy: user.name
        })
      }
      console.log(para);
      this.Loadingstart();
      // return;
      AddTaskCollectPerson(para).then(res => {
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: "success"
          });
          this.Loadignend();
          this.$refs["addForm"].resetFields();
          this.addFormVisible = false;
          this.getRegularMeetings();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error"
          });
        }
      });
    },
    //监听子组件事件 选择人员回调
    chooseUserCallBack(data) {
        console.log(data);
        var newdata = data.map(function (item) {
            return item["Id"];
        });
        var namenewdata = data.map(function (item) {
            return item["UserNameClaim"];
        });
        console.log(newdata);

        var str = "";
        for (var i = 0; i < namenewdata.length; i++) {
            str += namenewdata[i]+ ",";
        }
        if (str.length > 0) {
            str = str.substr(0, str.length - 1);
        }

        var idstr = "";
        for (var i = 0; i < newdata.length; i++) {
            idstr += newdata[i]+ ",";
        }
        if (idstr.length > 0) {
            idstr = idstr.substr(0, idstr.length - 1);
        }
        this.ccusername=str;
        this.addForm.PersonUserId = idstr;
        this.addForm.PersonName = str;
        this.addUserVisible=false;
    },
    //监听子组件事件 选择人员回调
    chooseUserCallBack2(data) {
        console.log(data);
        var newdata = data.map(function (item) {
            return item["Id"];
        });
        var namenewdata = data.map(function (item) {
            return item["UserNameClaim"];
        });
        console.log(newdata);

        var str = "";
        for (var i = 0; i < namenewdata.length; i++) {
            str += namenewdata[i]+ ",";
        }
        if (str.length > 0) {
            str = str.substr(0, str.length - 1);
        }

        var idstr = "";
        for (var i = 0; i < newdata.length; i++) {
            idstr += newdata[i]+ ",";
        }
        if (idstr.length > 0) {
            idstr = idstr.substr(0, idstr.length - 1);
        }
        this.ccusername2=str;
        this.editCCForm.CCUserCode = idstr;
        this.editCCForm.CCUserName = str;
        this.addUserVisible2=false;
    },
    //监听子组件事件 选择人员回调
    chooseUserCallBack3(data) {
        console.log(data);
        var newdata = data.map(function (item) {
            return item["Id"];
        });
        var namenewdata = data.map(function (item) {
            return item["UserNameClaim"];
        });
        console.log(newdata);

        var str = "";
        for (var i = 0; i < namenewdata.length; i++) {
            str += namenewdata[i]+ ",";
        }
        if (str.length > 0) {
            str = str.substr(0, str.length - 1);
        }

        var idstr = "";
        for (var i = 0; i < newdata.length; i++) {
            idstr += newdata[i]+ ",";
        }
        if (idstr.length > 0) {
            idstr = idstr.substr(0, idstr.length - 1);
        }
        this.fabuusername=str;
        this.editFabuForm.UsePeopleCode = idstr;
        this.editFabuForm.UsePeopleName = str;
        this.addUserVisible3=false;
    },
    handleChooseCCUser2()
    {
        this.addUserVisible2=true;
    },
    handleChooseCCUser3()
    {
        this.addUserVisible3=true;
    },
    handleChooseCCUser()
    {
      let self = this;
      var user = JSON.parse(window.localStorage.user);
      var userid = user.sub;
      QuerySearchQuanxianListByUsercode({ usercode: userid }).then(res => {
        // console.log(res);
        var dataArr=res.data.response;
        var n = 0;
        for(var d=0;d<dataArr.length;d++)
        {
          if(dataArr[d].Type==99 || dataArr[d].Type==1 || dataArr[d].Type==3)
          {
            n+=1;
            var dptIds=dataArr[d].DepartmentCode;
            var dptNames=dataArr[d].DepartmentName;
            self.chooseDeptId+=dptIds;
            self.chooseDeptId+=',';
            // console.log(self.chooseDeptId);
            // var dptIdArr=dptIds.split(',');
            // var dptNameArr=dptNames.split(',');
          }
        }
        if(n==0){
          self.chooseDeptId='0';
        }
        this.addUserVisible=true;
      });
    },
    //审核人回调
    assigncallFunction(newdata) {
      this.addForm.ApprovalCode = newdata[0];
      this.editForm.ApprovalCode = newdata[0];
      let para = { userId: newdata[0] };
      GetUserPostList(para).then(res => {
        for (var i = 0; i < res.data.data.length; i++) {
          this.addForm.ApprovalName = res.data.data[0].name;
          this.editForm.ApprovalName = res.data.data[0].name;
        }
      });
    },
    //会议发布人回调
    usercallFunction(newdata){
      let fabuusers = "";
      let fabuusercodes = "";
      if(newdata.length == 1){
        this.addForm.UsePeopleCode = newdata[0];
        let para = {userId: newdata[0]};
        GetUserPostList(para).then((res) => {
                this.addForm.UsePeopleName = res.data.data[0].name;
        });
      }else{
        newdata.map(item => {
          fabuusercodes += item + ",";
          this.addForm.UsePeopleCode = fabuusercodes.replace("null", "");
          let para = {userId: item};
          GetUserPostList(para).then((res) => {
                  fabuusers += res.data.data[0].name + ",";
                  this.addForm.UsePeopleName =  fabuusers.replace("null", "");
                  this.addForm.UsePeopleName = this.addForm.UsePeopleName.substr(0, this.addForm.UsePeopleName.length - 1);
          });
        })
      }
    },
    diaclose() {
      this.$refs.addForm.resetFields();
    },
    formatCreatedOn: function(row, colume) {
      return !row.LastUpdateTime || row.LastUpdateTime == ""
        ? ""
        : util.formatDate.format(new Date(row.LastUpdateTime), "yyyy-MM-dd");
    },
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    tableRowClassName({row, rowIndex}){
      if (rowIndex % 2 !== 0) {
      return 'warning-row';
      }
      return '';    
    },
    //修改抄送人
    handleEditCCUser() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "warning"
        });
        return;
      }
      this.editCCFormVisible = true;
      this.editCCForm.Id = row.Id;
    },
    //修改发布人
    handleEditFabuUser() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "warning"
        });
        return;
      }
      this.editFabuFormVisible = true;
      this.editFabuForm.Id = row.Id;
    },
    //修改审批人
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "warning"
        });
        return;
      }
      this.editFormVisible = true;
      this.editForm.Id = row.Id;
    },
    //删除
    handleDelete() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要移除的团队成员！",
          type: "warning"
        });
        return;
      }
      this.$confirm("确认要移除选中的团队成员吗？", "提示", {}).then(() => {
        this.Loadingstart();
        let para = { Id: row.Id };
        RemoveTaskCollectPerson(para).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success"
            });
            this.Loadignend();
            this.currentRow = null;
            this.getRegularMeetings();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error"
            });
          }
        });
      }).catch(()=>{});
    },
    getRegularMeetings() {
      var user = JSON.parse(window.localStorage.user);
      QueryCollectPersonListByCreatedId({userCode: user.sub}).then(res => {
        // console.log(res)
        this.datas = res.data.response;
      });
    },
    //新增
    handleAdd() {
      this.addFormVisible = true;
    },
    checkclose () {
      this.pValue1 = 0;
      this.$emit("closeFunction", {});
    },
    taskinfoCheckclose(){
      this.checkVisible = false
      this.pValue1 = 0
    },
    Loadingstart () {
      this.loading = Loading.service({
        lock: true,
        text: '加载中...',
        background: 'rgba(0,0,0,0.1)'
      })
    },
    Loadignend () {
      this.loading.close();
    },
    handleFormatTime(t)
    {
      return t;
    },
  },
  mounted () {
    
    this.dialogShow = true;
    this.getRegularMeetings();
    return;
    if(this.headerText)
    {
      this.myheadText = this.headerText;
    }
    if(this.type)
    {
      this.mytype = this.type;
      console.log(this.mytype)
    }
  }
}
</script>
<style scoped>
/deep/.el-drawer__close-btn .el-icon-close:before {
  color: #72767b;
}
</style>